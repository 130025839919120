import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { getAvatarProps } from 'utils/helpers';
import MenuPopover from './MenuPopover';
import { MessageTime } from './MessageTime';
import SubDirectoryArrowIcon from 'lib/icons/SubDirectoryArrowIcon';
import { Avatar } from 'antd';
import {
  MessageInfo,
  MessageWrap,
  MessageWrapper,
  ReplyAuthor,
  ReplyContent,
  ReplyIcon,
  ReplyText,
  ReplyWrapper,
  ResourceName,
  Text,
  Wrap,
} from './styles';
import { IAuthUser, ISubtaskEditMessage, SubtaskChatMessageDto } from 'types';

interface IMessageProps {
  messages: SubtaskChatMessageDto[];
  message: SubtaskChatMessageDto;
  user: IAuthUser;
  index: number;
  subtaskId: string;
  setDataForReply: Dispatch<SetStateAction<any>>;
  setEditMessage: ({ subtaskId, message }: ISubtaskEditMessage) => void;
  editItem: ISubtaskEditMessage;
  deleteMessage: ({
    subtaskId,
    messageId,
  }: {
    subtaskId: string;
    messageId: string;
  }) => Promise<void>;
  isScrolling: boolean;
  isDataForReplyExists: boolean;
  setScrollToReplyId: Dispatch<SetStateAction<string>>;
  scrollToReplyId: string;
  isProjectDeleted: boolean;
  isProjectArchived: boolean;
}

const Message = ({
  messages,
  message,
  user,
  index,
  subtaskId,
  setEditMessage,
  setDataForReply,
  editItem,
  deleteMessage,
  isScrolling,
  isDataForReplyExists,
  setScrollToReplyId,
  scrollToReplyId,
  isProjectDeleted,
  isProjectArchived,
}: IMessageProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { creator } = message;
  const resourceFullName = `${creator?.firstName} ${creator?.lastName}`;
  const { fullName, color, background } = getAvatarProps(resourceFullName);
  const isLast = useMemo(
    () => messages[index + 1] && messages[index + 1]?._id !== message._id,
    [messages, index, message._id]
  );

  const isYou = useMemo(
    () => message?.creator?._id === user._id,
    [message.creator?._id, user._id]
  );

  const replyMessage = useCallback(
    () => setDataForReply({ subtaskId, message }),
    [subtaskId, message, setDataForReply]
  );
  const handleEditMessage = useCallback(
    () => setEditMessage({ subtaskId, message }),
    [message, subtaskId, setEditMessage]
  );
  const handleDeleteMessage = useCallback(() => {
    setIsDeleting(true);
    deleteMessage({ subtaskId, messageId: message._id })
      .catch(e => console.error(e))
      .finally(() => setIsDeleting(false));
  }, [message._id, subtaskId, deleteMessage]);

  const isYouCreatorOfReply = useMemo(
    () => message.replyToMessage?.creator?._id === user?._id,
    [message.replyToMessage?.creator?._id, user._id]
  );
  const isMessageScrollIntoView = message._id === scrollToReplyId;
  const messageRef = useRef(null);

  useEffect(() => {
    if (isMessageScrollIntoView) {
      messageRef?.current?.scrollIntoView({
        block: 'center',
        inline: 'center',
      });

      const timer = setTimeout(() => setScrollToReplyId(null), 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isMessageScrollIntoView, setScrollToReplyId]);

  const handleClickReply = () => setScrollToReplyId(message.replyToMessage._id);

  return (
    <Wrap
      isLast={isLast}
      ref={messageRef}
      $isMessageScrollIntoView={isMessageScrollIntoView}
    >
      <MessageWrapper $isYou={isYou}>
        {!editItem &&
        !isDataForReplyExists &&
        !(isProjectDeleted || isProjectArchived) ? (
          <MenuPopover
            isScrolling={isScrolling}
            replyMessage={replyMessage}
            deleteMessage={handleDeleteMessage}
            editMessage={isYou ? handleEditMessage : null}
            isDeleting={isDeleting}
          />
        ) : (
          <div></div>
        )}
        <MessageWrap $isYou={isYou}>
          <MessageInfo $isYou={isYou}>
            <ResourceName>{isYou ? 'You' : resourceFullName}</ResourceName>
            {message.replyToMessage && (
              <ReplyWrapper onClick={handleClickReply}>
                <ReplyIcon>
                  <SubDirectoryArrowIcon />
                </ReplyIcon>
                <ReplyContent>
                  <ReplyAuthor>
                    {isYouCreatorOfReply
                      ? 'You'
                      : `${message.replyToMessage.creator.firstName} ${message.replyToMessage.creator.lastName}`}
                  </ReplyAuthor>
                  <ReplyText>{message.replyToMessage.body}</ReplyText>
                </ReplyContent>
              </ReplyWrapper>
            )}
            <Text>{message?.body}</Text>
            <MessageTime message={message} />
          </MessageInfo>
          <Avatar
            shape="square"
            size={33}
            className="avatar"
            src={creator?.photoUrl}
            style={{ color, background, borderRadius: '6.3px' }}
          >
            {fullName}
          </Avatar>
        </MessageWrap>
      </MessageWrapper>
    </Wrap>
  );
};

export default memo(Message, isEqual);
