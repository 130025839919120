import {
  Dispatch,
  FC,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { subtaskItemIdSelector, subtaskNameSelector } from 'store/gantt';
import { Task } from './elements/Task';
import { SubtasksTags } from 'components/SubtasksTags';
import CollapseButton from 'components/common/CollapseButton/CollapseButton';
import * as Styled from './styles';
import { IProjectTaskDto, SubtaskModeList, TaskInfoProps } from 'types';

interface TaskModeItemProps extends TaskInfoProps {
  subtask: SubtaskModeList;
  tasksList: IProjectTaskDto[];
  projectId: string;
  isProjectEditing?: boolean;
  setEditData: Dispatch<any>;
  toggleItemTemplateModal: () => void;
}

const TaskModeItemComponent: FC<TaskModeItemProps> = ({
  subtask,
  tasksList,
  projectId,
  isProjectEditing,
  setEditData,
  toggleItemTemplateModal,
  ...props
}) => {
  const subtaskItemId = useSelector(subtaskItemIdSelector);
  const subtaskName = useSelector(subtaskNameSelector);
  const scrollToRef = useRef(null);
  const isOpenInfo = useMemo(
    () => props.expandedTasks.includes(subtask.subtaskName),
    [props.expandedTasks, subtask.subtaskName]
  );
  const [isOpen, setIsOpen] = useState(isOpenInfo);

  const { tasks } = subtask;

  const shouldCurrentTaskHighlight = useMemo(
    () => subtask.subtasksIds.includes(subtaskItemId),
    [subtask.subtasksIds, subtaskItemId]
  );

  const handleOpenInfo = (open: boolean) => {
    if (!open) {
      props.setExpandedTasks(prev => [...prev, subtask.subtaskName]);
    } else {
      props.setExpandedTasks(prev =>
        prev.filter(name => name !== subtask.subtaskName)
      );
    }
    setIsOpen(open);
  };

  useEffect(() => {
    setIsOpen(isOpenInfo);

    if (isOpenInfo) scrollToRef?.current?.scrollIntoView();
  }, [isOpenInfo]);

  useEffect(() => {
    if (shouldCurrentTaskHighlight && !isOpenInfo) {
      props.setExpandedTasks([...props.expandedTasks, subtask.subtaskName]);
    }
  }, [shouldCurrentTaskHighlight, isOpenInfo, props, subtask.subtaskName]);

  useEffect(() => {
    if (subtaskName === subtask.subtaskName && !isOpenInfo) {
      props.setExpandedTasks(prev => [...prev, subtask.subtaskName]);
    }
  }, [isOpenInfo, props, subtask.subtaskName, subtaskName]);

  return (
    <Styled.TaskModeItemWrapper ref={scrollToRef}>
      <Styled.Content onClick={() => handleOpenInfo(isOpen)}>
        <Styled.Header>
          <Styled.Title>{subtask.subtaskName}</Styled.Title>
          <CollapseButton isOpen={isOpen} />
        </Styled.Header>
        {!isOpen && (
          <>
            <Styled.Divider />
            <Styled.Wrapper>
              {!!tasks.length && (
                <SubtasksTags
                  projectId={projectId}
                  color={props.color}
                  notAvailableProject={false}
                  subtasks={subtask.subtasks}
                  toggleParent={props.toggleParent}
                  invoice={props.invoice}
                  taskName={tasks[0].name}
                  taskOrder={1}
                />
              )}
            </Styled.Wrapper>
          </>
        )}
      </Styled.Content>
      {isOpen && (
        <Styled.ItemWrapper>
          {tasks.length > 1 && (
            <Styled.Count>Items ({tasks.length})</Styled.Count>
          )}
          <Styled.List
            dataSource={tasks}
            rowKey={(task: IProjectTaskDto) => task._id}
            renderItem={(task: IProjectTaskDto, index) => (
              <Task
                {...props}
                tasksList={tasksList}
                task={task}
                index={index}
                isProjectEditing={isProjectEditing}
                setEditData={setEditData}
                toggleItemTemplateModal={toggleItemTemplateModal}
              />
            )}
          />
        </Styled.ItemWrapper>
      )}
    </Styled.TaskModeItemWrapper>
  );
};

export const TaskModeItem = memo(TaskModeItemComponent, isEqual);
